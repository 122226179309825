<template>
  <div>
    <ics-page-inner v-if="isConfirm" title="还款信息">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <el-col :span="12">
          <el-form-item label="还款金额(元)">
            <p>{{ utils.moneyFormat(payInfo.repaymentAmount, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款期限">
            <p>{{ utils.dateFormat(payInfo.loanEnd) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="本金">
            <p>{{ utils.moneyFormat(payInfo.theCapital, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="利息">
            <p>{{ utils.moneyFormat(payInfo.theInterest, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款状态">
            <p>{{ utils.statusFormat(payInfo.recordStatus, 'recordStatus') }}</p>
          </el-form-item>
        </el-col>
        <el-col />
        <el-col :span="12">
          <el-form-item label="收款日期" prop="collectionDate" :rules="{ required: true, message: '请选择收款日期', trigger: 'change' }">
            <ics-search-date :date.sync="appForm.collectionDate" type="date" style="width: 100%;" />
          </el-form-item>
        </el-col>
        <el-col />
        <el-col :span="24" style="display: flex;justify-content:center;margin-bottom: 50px;padding-top: 30px;">
          <el-button @click="closeInfo">
            关 闭
          </el-button>
          <debounce-button :loading="loadingSubmit.submit" type="primary" style="margin-right: 10px;" @click="submitForms">
            确 认
          </debounce-button>
        </el-col>
      </el-form>
    </ics-page-inner>
    <ics-page-inner v-if="!isConfirm" title="还款信息">
      <el-form label-width="180px" :label-suffix="constants.labelSuffix">
        <el-col :span="12">
          <el-form-item label="还款金额(元)">
            <p>{{ utils.moneyFormat(payInfo.repaymentAmount, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款期限">
            <p>{{ utils.dateFormat(payInfo.loanEnd) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="本金">
            <p>{{ utils.moneyFormat(payInfo.theCapital, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="利息">
            <p>{{ utils.moneyFormat(payInfo.theInterest, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款状态">
            <p>{{ utils.statusFormat(payInfo.recordStatus, 'recordStatus') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="payInfo.pay" :span="12">
          <el-form-item label="还款凭证">
            <p><a href="javascript:" class="text-btn" @click="utils.downloadP('repay', payInfo.pay.url)">{{ payInfo.pay.name || '-' }}</a></p>
          </el-form-item>
        </el-col>
        <el-col />
        <el-col :span="12">
          <el-form-item label="收款日期">
            <p>{{ utils.dateFormat(payInfo.collectionDate) }}</p>
          </el-form-item>
        </el-col>
        <el-col />
        <el-col v-if="payInfo.co" :span="12">
          <el-form-item label="收款凭证">
            <p><a href="javascript:" class="text-btn" @click="utils.downloadP('repay', payInfo.co.url)">{{ payInfo.co.name || '-' }}</a></p>
          </el-form-item>
        </el-col>
      </el-form>
    </ics-page-inner>
  </div>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsSearchDate from '@/components/search-date'
export default {
  name: 'IcsPayBackInner',
  components: { IcsSearchDate },
  mixins: [routeEnterMixin],
  props: {
    isConfirm: {
      type: Boolean,
      default: false
    },
    payType: {
      type: String,
      default: ''
    },
    businessId: {
      type: [String, Number],
      default: ''
    },
    loadingSubmit: {
      type: Object,
      default() {
        return {}
      }
    },
    planDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data () {
    return {
      appForm: {
        collectionDate: '',
        files: []
      },
      dialog: {
        visible: false,
        title: '上传凭证'
      },
      payInfo: {},
      rules: {}
    }
  },
  watch: {
    businessId: {
      handler (val) {
        if (val) {
          this.getDetail()
        }
      },
      immediate: true
    },
  },
  created () {
  },
  methods: {
    getDetail() {
      this.api.payBack.recordDetails(this.businessId).then(result => {
        const data = result.data.data.repaymentPlanRecord
        result.data.data.files.forEach(item => {
          if (item.fileType === 'pay') {
            data.pay = item || {}
          }
          if (item.fileType === 'co') {
            data.co = item || {}
          }
        })
        this.payInfo = data
      })
    },
    clickDialog () {
      this.dialog.visible = true
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        data.id = this.payInfo.id
        this.$emit('submit', data)
      })
    },
    closeInfo () {
      this.appForm.files = []
      this.appForm.collectionDate = ''
      this.loadingSubmit.submit = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
